"use client";
import { Inter } from "next/font/google";
import "./globals.css";
import TopNavigationBar from "@/components/topnav/TopNavigationBar";
import { ApolloWrapper } from "@/app/ApolloWrapper";
import React, { useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { HomeIcon } from "@heroicons/react/16/solid";
import {
	Button,
	Dialog, DialogBody, DialogFooter, DialogHeader,
	Option,
	Popover,
	PopoverContent,
	PopoverHandler, Select, Spinner,
	Typography
} from "@material-tailwind/react";
import { HiCubeTransparent } from "react-icons/hi2";
import { AuthenticationLoggedInEvent, AuthorizationCheckRequired, eventBus, OrganizationChange, PmStore } from "@/state";
import {
	HiOutlineDocumentText, HiOutlineInformationCircle, HiOutlineNewspaper,
	HiOutlineOfficeBuilding,
	HiOutlineUserCircle,
	HiOutlineUserGroup,
	HiOutlineViewGrid, HiViewGrid
} from "react-icons/hi";
import { usePathname, useRouter } from "next/navigation";
import { GoGear } from "react-icons/go";
import { CiMap } from "react-icons/ci";
import { TbReportMoney } from "react-icons/tb";
import { TbReportAnalytics } from "react-icons/tb";
import { IoBriefcaseOutline, IoLocationOutline } from "react-icons/io5";
import { LuMessageSquare } from "react-icons/lu";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, Zoom, toast } from "react-toastify";
import {AllCommunityModule, ModuleRegistry} from "ag-grid-community";


const inter = Inter({ subsets: ["latin"] });

export default function RootLayout({ children }: Readonly<{ children: React.ReactNode; }>) {
	let store = PmStore();
	const router = useRouter()
	const pathname = usePathname();

	//init ag grid
	ModuleRegistry.registerModules([AllCommunityModule]);

	const pmStoreStateRef = useRef(PmStore.getState());
	useEffect(() => PmStore.subscribe((state) => (pmStoreStateRef.current = state)), [])



	//on load, let's do an immediate check
	useEffect(() => CheckAuth(), []);




	// let's create an internval and check periodically
	useEffect(() => {

		const intervalId = setInterval(() => {
			CheckAuth();
		}, 5000);

		return () => clearInterval(intervalId);


	}, [store]);


	function CheckAuth() {

		if (pmStoreStateRef.current.isAuthenticated) {
			const decoded = jwtDecode(pmStoreStateRef.current.token);
			let currentDate = new Date();

			if (decoded.exp * 1000 < currentDate.getTime()) {
				console.log("jwt has expired")
				store.resetToken();
				router.push("/");
				pmStoreStateRef.current.isAuthenticated = false;
			}
			else {
				//nothing
			}

		}
		else {
			if (pathname.startsWith("/manage")) {
				router.push("/");
			}
			else {
				//nothing
			}
		}

	}



	eventBus.subscribe((event) => {
		switch (event.type) {
			case AuthorizationCheckRequired:
				CheckAuth();
				break;
			default:
				break;
		}
	});








	return (
		<html lang="en">
			<head>
				<title>Rental Flow</title>
				<script defer data-domain="rentalflow.ai" src="https://plausible.io/js/script.js"></script>
				<link
					rel="icon"
					href="/logo.svg"
					type="image/svg"
				/>
				<script
					id="zumrailsconnector"
					src="https://cdn.aggregation.zumrails.com/sandbox/connector.js"
					type="text/javascript"
					async=""
				></script>
				<script
					id="zumrailssdk"
					src="https://cdn.zumrails.com/sandbox/zumsdk.js"
					type="text/javascript"
					async=""
				></script>
			</head>


			<body className={inter.className + " w-full "}>



				<div>
					<ApolloWrapper>


						<Navigation />


						<div className={store.isAuthenticated ? "ml-12  h-[calc(100vh-5vh)]" : "p-0 h-[calc(100vh-5vh)]"}>
							{children}

							<Toaster />
							<div className={"z-[999]"}>
								<ToastContainer
									className={"absolute"}
									autoClose={3000}
									limit={4}
									hideProgressBar={false}
									newestOnTop
									closeOnClick={false}
									rtl={false}
									pauseOnFocusLoss={false}
									draggable
									pauseOnHover={false}
									theme="dark"
									transition={Zoom}
									stacked={true}
								/>
							</div>

						</div>
					</ApolloWrapper>
				</div>


			</body>
		</html>
	);
}


function Navigation() {
	let store = PmStore();
	let router = useRouter();
	const [SideOpen, SetSideOpen] = React.useState(false);
	const MenuOptions = [
		{
			Title: "Leasing Hub",
			Path: "/manage/hub/leasing",
			Icon: <HiCubeTransparent className={"h-4 w-4"} />
		},
		{
			Title: "Communication Hub",
			Path: "/manage/hub/communication",
			Icon: <LuMessageSquare className={"h-4 w-4"} />
		},
		{
			Title: "Financial Hub",
			Path: "/manage/hub/financial",
			Icon: <TbReportMoney className={"h-4 w-4"} />
		},
		// {
		// 	Title: "Operations Hub",
		// 	Path: "/manage/hub/leasing",
		// 	Icon: <HiCubeTransparent className={"h-4 w-4"} />
		// },
		{
			Title: "Applications",
			Path: "/manage/application",
			Icon: <HiOutlineDocumentText className={"h-4 w-4"} />
		},
		{
			Title: "Assets",
			Path: "/manage/asset",
			Icon: <HiOutlineOfficeBuilding className={"h-4 w-4"} />
		},
		{
			Title: "Cases",
			Path: "/manage/case",
			Icon: <IoBriefcaseOutline className={"h-4 w-4"} />
		},
		{
			Title: "Site Maps",
			Path: "/manage/site-map",
			Icon: <CiMap className={"h-4 w-4"} />
		},
		{
			Title: "Tenants",
			Path: "/manage/tenant",
			Icon: <HiOutlineUserGroup className={"h-4 w-4"} />
		},
		{
			Title: "Settings",
			Path: "/manage/settings",
			Icon: <GoGear className={"h-4 w-4"} />
		},
	]


	function Goto(path) {
		router.push(path);
		SetSideOpen(false);
	}


	const [OpenModal, setOpenModal] = React.useState(false);
	const handleOpen = () => setOpenModal(!OpenModal);
	const selectedOrgRef = useRef(PmStore.getState().selectedOrganization);
	const KnownOrganizations = PmStore((state) => state.knownOrganizations)





	function SetupSelectedOrg() {
		if (KnownOrganizations.length === 0) return;
		if (store?.selectedOrganization === null) {
			let selected = KnownOrganizations[0];
			store.setSelectedOrg(selected);
		}
	}

	function SetupLoggedInUser() {
		if (store?.isAuthenticated) {
			if (store?.knownOrganizations.length === 0) {
				GetOrganizations();
				SetupSelectedOrg();
			} else {
				SetupSelectedOrg();
			}
		}
	}

	eventBus.subscribe((event) => {
		switch (event.type) {
			case AuthenticationLoggedInEvent:
				SetupLoggedInUser();
				break;
			default:
				break;
		}
	});

	function ChangeOrganizations(orgId) {
		var org = KnownOrganizations.find(item => item.id === orgId)
		store.setSelectedOrg(org)

		router.push("/manage");
		eventBus.next({ type: OrganizationChange })
		setOpenModal(false);
		window.location.reload();
	}




	return (
		<div >
			<div className={`transition duration-1000 ${store.isAuthenticated ? "  ease-in-out " : " transform ease-out-in  hidden"}`}>
				<aside className={`flex h-screen  z-50  fixed transform duration-1000  bg-gray-900  w-60  transition  ease-in-out ${SideOpen ? "translate-x-none" : " -translate-x-48"}`}>
					<div className={SideOpen ? "translate-x-0   w-full -right-6 transition transform ease-in duration-300 flex items-center justify-between border-4 border-white dark:border-[#0F172A] bg-[#1E293B]  absolute top-2 rounded-full h-12" : "translate-x-24 scale-x-0"}>
						<div className="flex w-full ml-1 items-center space-x-3 group bg-gradient-to-r  from-rf-orange  to-rf-orange pl-10 pr-2 py-1 rounded-full text-white  ">
							<div className="transform ease-in-out duration-300 mr-12">
								Menu
							</div>
						</div>
					</div>


					<div onClick={() => SetSideOpen(!SideOpen)}
						className="-right-6 transition transform ease-in-out duration-500 flex border-4 border-white dark:border-[#0F172A]   bg-rf-orange absolute top-2 p-3 rounded-full text-rf-blue hover:rotate-45">
						<HiOutlineViewGrid className={"w-4 h-4"} />
					</div>


					<div className={"h-full mt-20 w-full grid grid-cols-1"}>
						<div className={" h-full"}>
							<SideMenuOption Title={"Dashboard"} Icon={<HomeIcon className={"h-4 w-4"} />}
								OnClick={() => Goto("/manage")} />


							{MenuOptions.map((row, index) => <SideMenuOption key={index} Title={row.Title}
								Icon={row.Icon}
								OnClick={() => Goto(row.Path)} />)}

						</div>



						<div className="absolute bottom-0 left-0 right-0 mb-4">
							<div>
								<SideMenuOption Title={"Help"}
									Icon={<HiOutlineInformationCircle className={"h-4 w-4"} />}
									OnClick={() => Goto("/manage/help")} />
								<SideMenuOption Title={"Profile"} Icon={<HiOutlineUserCircle className={"h-4 w-4"} />}
									OnClick={() => Goto("/manage/profile")} />
								<OrgSelection AllOrganizations={KnownOrganizations} CurrentOrgId={selectedOrgRef.current?.id ?? KnownOrganizations[0]?.id} OnSelect={ChangeOrganizations} />
							</div>

						</div>
					</div>




				</aside>
			</div>


			<div>
				<TopNavigationBar />
			</div>
		</div>
	)




	function SideMenuOption({ Title, Icon, OnClick }) {

		const [openPopover, setOpenPopover] = React.useState(false);

		const triggers = {
			onMouseEnter: () => setOpenPopover(true),
			onMouseLeave: () => setOpenPopover(false),
		};


		return (
			<div onClick={OnClick} className={"mt-1 mb-1"}>
				<div className={SideOpen ? "hidden" : ""}>
					<Popover open={openPopover} handler={setOpenPopover} placement="right">
						<PopoverHandler {...triggers}>
							<div className="hover:ml-4 hover:cursor-pointer justify-end pr-5 text-white hover:text-rf-orange dark:hover:text-blue-500 w-full bg-[#1E293B] p-3 rounded-full transform ease-in-out duration-300 flex">
								{Icon}
							</div>
						</PopoverHandler>
						<PopoverContent className="z-50 ">
							<Typography variant="small" color="gray" className="font-normal ">
								{Title}
							</Typography>
						</PopoverContent>
					</Popover>
				</div>
				<div
					className={SideOpen ? "hover:ml-4 hover:cursor-pointer w-full text-white hover:text-rf-orange dark:hover:text-blue-500 bg-[#1E293B] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3" : "hidden"}>
					{Icon}
					<div>
						{Title}
					</div>
				</div>

			</div>
		)
	}
	function OrgSelection({ AllOrganizations, CurrentOrgId, OnSelect }) {
		const [openPopover, setOpenPopover] = React.useState(false);
		const PopoverTriggers = { onMouseEnter: () => setOpenPopover(true), onMouseLeave: () => setOpenPopover(false), };

		var NotLoading = AllOrganizations != null && AllOrganizations.length > 0 && CurrentOrgId != null
		var CurrentOrg = AllOrganizations.find(item => item.id === CurrentOrgId)

		function change(e) {
			OnSelect(e);
		}


		return [
			(<div key={"org-info"} onClick={handleOpen}>
				<div className={SideOpen ? "hidden" : ""}>
					<Popover open={openPopover} handler={setOpenPopover} placement="right">
						<PopoverHandler {...PopoverTriggers}>
							<div className="hover:ml-4 hover:cursor-pointer justify-end pr-5 text-white hover:text-rf-orange dark:hover:text-blue-500 w-full bg-[#1E293B] p-3 rounded-full transform ease-in-out duration-300 flex">
								{!NotLoading ? <div className={"flex justify-center"}> <Spinner /> </div> : <HiViewGrid />}

							</div>
						</PopoverHandler>
						<PopoverContent className="z-50 ">

							<Typography variant="small" color="gray" className="font-normal ">
								{CurrentOrg?.name ?? "Unknown"}
							</Typography>

						</PopoverContent>
					</Popover>
				</div>
				<div
					className={SideOpen ? "hover:ml-4 hover:cursor-pointer w-full text-white hover:text-rf-orange dark:hover:text-blue-500 bg-[#1E293B] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3" : "hidden"}>
					{!NotLoading ? <div className={"flex justify-center"}> <Spinner /> </div> : <HiViewGrid />}

					<div>
						{CurrentOrg?.name ?? "Unknown"}
					</div>
				</div>
			</div>),
			(<Dialog key={"org-dialog"} open={OpenModal} handler={null} dismiss={{ enabled: false, escapeKey: false, outsidePress: false }}>
				<DialogHeader>Change Organization</DialogHeader>
				<DialogBody>
					<Typography>
						For those who have access to more than one organization, below you can toggle between them.
					</Typography>


					<div className={"pt-5"}>
						<Select label={"Selected Org"} value={CurrentOrgId} onChange={(id) => change(id)}>
							{AllOrganizations?.map((row, index) => { return (<Option key={index} value={row.id}><small>{row.name}</small></Option>); })}
						</Select>
					</div>
				</DialogBody>
				<DialogFooter>
					<Button
						variant="text"
						color="red"
						onClick={() => setOpenModal(false)}
						className="mr-1"
					>
						<span>Close</span>
					</Button>
				</DialogFooter>
			</Dialog>)
		];

	}

}